import React, { Component } from "react";
import ReactApexChart from 'react-apexcharts';

class ProprietorPerformanceGraph extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      GraphicalData: this.props.filteredData, // Using filtered data passed as a prop
      start_date: this.props.start_date,
      end_date: this.props.end_date,
      proprietor: this.props.proprietor,
      size: this.props.size,
    };
  }

  componentDidUpdate(prevProps) {
    // Check if new filtered data or dates are passed as props
    if (
      this.props.filteredData !== prevProps.filteredData ||
      this.props.start_date !== prevProps.start_date ||
      this.props.end_date !== prevProps.end_date
    ) {
      this.setState({
        GraphicalData: this.props.filteredData,
        start_date: this.props.start_date,
        end_date: this.props.end_date,
      });
    }
  }

  render() {
    const { GraphicalData } = this.state;

    const entryFrequency = (obj) => {
      const level_freq = {};
      obj.forEach(idx => {
        if (level_freq[idx]) {
          level_freq[idx]++;
        } else {
          level_freq[idx] = 1;
        }
      });
      return level_freq;
    };

    const national_standards = [];
    let level_one_data = [];
    let level_two_data = [];
    let level_three_data = [];
    let level_four_data = [];

    // Processing the filtered data
    for (let i = 0; i < GraphicalData.length; i++) {
      const school = GraphicalData[i];

      if (!national_standards.includes(school.proprietor)) {
        national_standards.push(school.proprietor);
      }

      const uncategorized_levels = [];
      uncategorized_levels.push(school.performance_level);

      const categorized_levels = entryFrequency(uncategorized_levels);
      let total_level_data = 0;

      for (const level in categorized_levels) {
        total_level_data += categorized_levels[level];
      }

      level_one_data[i] = categorized_levels['1'] ? (categorized_levels['1'] / total_level_data) * 100 : 0;
      level_two_data[i] = categorized_levels['2'] ? (categorized_levels['2'] / total_level_data) * 100 : 0;
      level_three_data[i] = categorized_levels['3'] ? (categorized_levels['3'] / total_level_data) * 100 : 0;
      level_four_data[i] = categorized_levels['4'] ? (categorized_levels['4'] / total_level_data) * 100 : 0;
    }

    const series = [
      { name: 'Level 1', data: level_one_data },
      { name: 'Level 2', data: level_two_data },
      { name: 'Level 3', data: level_three_data },
      { name: 'Level 4', data: level_four_data },
    ];

    const options = {
      chart: { type: 'bar', height: 300, stacked: true, stackType: '100%' },
      colors: ['#ff0000', '#0018ff', '#fffc00', '#1bb60b'],
      xaxis: { categories: national_standards },
      yaxis: {
        max: 100,
        labels: { formatter: (value) => value.toFixed(0) + '%' },
      },
      title: {
        text: `Performance by Proprietor from ${this.state.start_date} to ${this.state.end_date}`,
        align: 'center',
        style: { fontSize: '12px', fontWeight: 'bold', color: '#263238' },
      },
      legend: { position: 'bottom' },
    };

    return (
      <div className={this.state.size === "fullscreen" ? "col-xs-12" : "col-xs-12 col-md-6"}>
        <div className="card">
          <ReactApexChart options={options} series={series} type="bar" height={300} />
        </div>
      </div>
    );
  }
}

export default ProprietorPerformanceGraph;
