import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import {Container, Row, Card, CardBody, Alert, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import Avatar from '@mui/material/Avatar';
import { connect } from "react-redux";

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';


import Loader from "../../../components/Loader";
// actions

// import images
import logo from "../../../assets/images/gov-logo.png";

import backend_url from "../../../routes/API_URL";

const API_URL = backend_url['api_url'];



class ResetPassword extends Component {
  constructor(props) {
        super(props);
		
		const id=this.props.match.params.id;
		
		const email=this.props.match.params.email;
		
		const token = this.props.match.params.token;
		
        this.state = {
			ModalVisible:true,
			user_password:'',
			email_id : email,
			bearer_auth: token,
			id : id,
			is_loading: false,
			error:false,
			is_success:false,
			
        };
        this.toggleModal = this.toggleModal.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.updatePassword = this.updatePassword.bind(this);
		this.IsAlphaNumeric = this.IsAlphaNumeric.bind(this);
		
    }

	toggleModal(){
		if(this.state.ModalVisible==false)
		{
		 this.setState({ModalVisible:true});
		}else{
		 this.setState({ModalVisible:false}); 
		}
  }
  
  //Form inputs handler and validator
  handleInput(e) {
    e.preventDefault();
      if(e.target.name==='user_password'){ 
		    if(e.target.value!='' && this.IsAlphaNumeric(e.target.value)){
			   this.setState({UpwordState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({UpwordState :'has-danger'});
		   }
	   }else if(e.target.name==='user_password_confirm'){
		    if(e.target.value!='' && e.target.value === this.state.user_password){
			   this.setState({UpwordConfirmState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({UpwordConfirmState :'has-danger'});
		   }
	   }else if(e.target.name==='email_id'){
		    if(e.target.value!='' && e.target.value === this.state.user_password){
			   this.setState({EmailState :'has-success',[e.target.id]: e.target.value});
		   }else{
			   this.setState({EmailState :'has-danger'});
		   }
	   }
        
  }
	
   //updating password
   updatePassword(event){
		event.preventDefault()

        const { history } = this.props;
        
		//const id = this.state.id;
		
        const user = {
		  email_id : this.state.email_id,
		  user_password : this.state.user_password,
		  id:this.state.id,
		  
        }
		
		this.setState({is_loading : true});
		
		//console.log(user);
        //axios.patch(API_URL + `/api/users/update/${id}`, user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
        axios.patch(API_URL + `/guest/reset_password/${this.state.id}`, user)
          .then(response => {
            this.setState({ModalVisible:false, is_loading:false, is_success:true,error:false,});
          })
          .catch(error => {
            this.setState({
				ModalVisible:false, 
				error : true,
				is_loading:false,
				is_success:false,
				
				});
          }) 
	 }
	 
	 //Validate paswsord
	IsAlphaNumeric = (f_string) => {
		const expression = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.,;~%?!@$%^&*-]).{8,}$/;
		return f_string.match(expression);
	}

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  <Card className="overflow-hidden">
                    <div className="bg-danger">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          RESET PASSWORD
                        </h5>
                        <Link to="/" className="logo logo-admin">
                          <img src={logo} height="45" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        <Form onSubmit={this.updatePassword} >
							{this.state.is_loading ? <Loader /> : null}
							
						  <ModalBody>
                                {this.state.error ? (
									<Alert color="danger"><center>Failed to reset your password. Retry...</center></Alert>
								  ) : null}
								  
								  {this.state.is_success ? (
									<Alert color="success"><center>Password reset is successful. Proceed to login below..</center></Alert>
								  ) : null}
								<FormGroup>
                                  <Row>
                                    
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <Input type="password" className="form-control" name="user_password" id="user_password" pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.,;~%?!@$%^&*-]).{8,}$" invalid={this.state.UpwordState==='has-danger'} onChange={(e)=>{this.handleInput(e); }} placeholder='Enter password' required/>
                                       <FormFeedback invalid>
										  The password must be atleast 8 characters long and this must be mixed cases alpha-numeric with special characters.
									   </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
								<FormGroup>
                                  <Row>
                                    
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <Input type="password"  className="form-control" name="user_password_confirm" id="user_password_confirm" invalid={this.state.UpwordConfirmState==='has-danger'} onChange={(e)=>{this.handleInput(e); }} placeholder='Re-enter password' required/>
                                       <FormFeedback invalid>
										  Password Mismatched. Retry...
									   </FormFeedback>
									</Col>
                                  </Row>
                                </FormGroup>
								
                          </ModalBody>
                          <ModalFooter>
                            <Button type="submit" color="primary">Reset</Button>
						  </ModalFooter>
						  
							<Row className="form-group mt-2 mb-0">
									<div className="col-12 mt-4">
									  
									  <center>
											<Link to="/login">
												Go to Login
											</Link>
									  </center>
									  
									</div>
							</Row>

                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                {
					/*
						<div className="mt-5 text-center">
						  <p>
							Incase you are not a regisered user, please contact the system administrator ?{" "}
							<Link
							  to="pages-register"
							  className="font-weight-medium text-primary"
							>
							  {" "}
							  Signup now{" "}
							</Link>{" "}
						  </p>
						  <p className="mb-0">
							© {new Date().getFullYear()} Veltrix. Crafted with{" "}
							<i className="mdi mdi-heart text-danger"></i> by Themesbrand
						  </p>
						</div>
					
					*/
				}
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ResetPassword;
