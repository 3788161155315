import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete, ArrowLeft} from '@material-ui/icons';

import ClusterAvgPerformanceGraph from './ClusterAvgPerformanceGraph';
import ClusterNesAvgPerformanceGraph from './ClusterNesAvgPerformanceGraph';
import DistrictAvgPerformanceGraph from './DistrictAvgPerformanceGraph';
import DistrictAvgNesPerformanceGraph from './DistrictAvgNesPerformanceGraph';
import DivisionAvgPerformanceGraph from './DivisionAvgPerformanceGraph';
import DivisionNesAvgPerformanceGraph from './DivisionNesAvgPerformanceGraph';
import NationalAvgPerformanceGraph from './NationalAvgPerformanceGraph';
import NationalNesAvgPerformanceGraph from './NationalNesAvgPerformanceGraph';
import SchoolAvgPerformanceGraph from './SchoolAvgPerformanceGraph';
import EstablishmentTypePerformanceGraph from './EstablishmentTypePerformanceGraph';
import ProprietorPerformanceGraph from './ProprietorPerformanceGraph';
import SchoolTypePerformanceGraph from './SchoolTypePerformanceGraph';
import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

import swal from 'sweetalert';

const API_URL = backend_url['api_url'];

//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class PerformanceReport extends Component {
  constructor(props) {
    super(props);
	//const visit_id=this.props.match.params.visit_id;
	
	
    const urole = window.sessionStorage.getItem("urole");
	
	this.state = {

	  modalVisible:false,
      EditModalVisible:false,
	  //NOTE: For the sake of the graph, the default values for emis_id, district_id,division_id and zone_id have been set to default values
	  emis_id:(urole=='Head Teacher' || urole=='Teacher')? window.sessionStorage.getItem("emis"):'',
      zone_id:(urole=='PEA' || urole=='Secondary Advisor' || urole=='Head Teacher' || urole=='Teacher')? window.sessionStorage.getItem("zone"):'',
      district_id:(urole=='DEM' || urole=='PEA' || urole=='Secondary Advisor' || urole=='Head Teacher' || urole=='Teacher')? window.sessionStorage.getItem("district"):'',
      division_id:(urole=='Division Manager' || urole=='DEM' || urole=='PEA' || urole=='Secondary Advisor' || urole=='Head Teacher' || urole=='Teacher')? window.sessionStorage.getItem("division"):'',
	  visit_id:'',
	  sector_id:window.sessionStorage.getItem('sector'),
	  //visit_id :visit_id,
      bearer_auth:window.sessionStorage.getItem('token'),
	  Sectors:[],
	  start_date:'',
	  end_date:'',
	  Districts:[],
	  Zones:[],
	  Divisions:[],
	  Schools:[],
	  filteredSchools: [], 
	  loading : '',
	  urole : urole,
	  establishment_type: '', // for establishment type
	  proprietor: '', // for proprietor
	  school_type: '', // for school type
	  // Lists for dropdown options
	  establishmentTypes: ['Form 1-4', 'Form 1-2', 'Form 2-3', 'Form 3-4', 'Full Primary', 'LEA'],
	  proprietors: ['Public', 'Private', 'Religious Institution'],
	  schoolTypes: ['Co-Education', 'Boys Only', 'Girls Only'],
    };

    //Toggle modal
    this.toggle = this.toggle.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleInput = this.handleInput.bind(this);
	
	this.filterDistricts = this.filterDistricts.bind(this);
	this.autoFilterDistricts = this.autoFilterDistricts.bind(this);
	this.filterZones = this.filterZones.bind(this);
	this.autoFilterZones = this.autoFilterZones.bind(this);
	this.filterSchools = this.filterSchools.bind(this);
	this.autoFilterSchools = this.autoFilterSchools.bind(this);
	
	this.triggerGraph = this.triggerGraph.bind(this);
	
	//Automatic filtering of reports 
	if(this.state.division_id!='' && this.state.Districts.length==0){
		this.autoFilterDistricts();
	}
	
	if(this.state.district_id!='' && this.state.Zones.length==0){
		this.autoFilterZones();
	}
	
	if(this.state.zone_id!='' && this.state.Schools.length==0){
		this.autoFilterSchools();
	}
	
	this.formatDate = this.formatDate.bind(this);
  }

  //Handle the display of the modal
	toggle(){
    if(this.state.modalVisible==false)
    {
     this.setState({modalVisible:true});
    }else{
     this.setState({modalVisible:false}); 
    }
  }
  toggleEdit(){
    if(this.state.EditModalVisible==false)
    {
     this.setState({EditModalVisiblet:true});
    }else{
     this.setState({EditModalVisible:false}); 
    }
  }
  
   //Format date 
   formatDate(date){
	   
	   return new Date(date).toLocaleDateString('en-GB');
   }
   //Filtering
	 filterDistricts(e){
		 e.preventDefault();
		 //this.setState({loading : true});
		  //axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   Districts:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the districts. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 //Auto filter districts
	 autoFilterDistricts(){
		 
		 axios.get(API_URL + `/districts/filter/${this.state.division_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
				this.setState({
				   Districts:response.data,
				   //loading : false,
			   }) 
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the districts. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 filterZones(e){
		 e.preventDefault();
		 
		 //this.setState({loading : true});
		 
		  //axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				  Zones:response.data,
				  //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the zones. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 //Auto filter zones
	 autoFilterZones(){
		 
		  //axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/zones/filter/${this.state.district_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				  Zones:response.data,
				  //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the zones. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
	 filterSchools(e) {
		e.preventDefault();
		
		const zone_id = e.target.value; // Get selected zone ID
	
		// Make axios request to fetch schools based on the selected zone
		axios.get(`${API_URL}/schools/filter/${zone_id}`, { 
			headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }
		})
		.then(response => {
			if (response.data.length > 0) {
				this.setState({
					Schools: response.data,               // Store all schools in the state
					filteredSchools: response.data,       // Initially, show all schools in filteredSchools
					establishment_type: '',               // Reset establishment_type filter
				});
			} else {
				// Handle case when no data is returned
				swal({
					title: "No Schools Found",
					text: "No schools were found for the selected zone.",
					icon: "info",
					button: true
				});
			}
		})
		.catch(error => {
			this.handleError(error);
		});
	}
	
	
	filterByEstablishmentType(e) {
		const selectedEstablishmentType = e.target.value;
	
		// Filter the schools based on the selected establishment_type
		const filteredSchools = this.state.Schools.filter(school => 
			school.establishment_type === selectedEstablishmentType
		);
	
		this.setState({
			establishment_type: selectedEstablishmentType,  // Update the selected establishment type
			filteredSchools: filteredSchools,               // Update the filtered list of schools
		});
	}
	
	filterByProprietor(e) {
		const selectedProprietor = e.target.value;
	
		// Filter the schools based on the selected proprietor
		const filteredSchools = this.state.Schools.filter(school => 
			school.proprietor === selectedProprietor && 
			(this.state.establishment_type ? school.establishment_type === this.state.establishment_type : true)
		);
	
		this.setState({
			proprietor: selectedProprietor,         // Update the selected proprietor
			filteredSchools: filteredSchools,       // Update the filtered list of schools
		});
	}
	
	filterBySchoolType(e) {
		const selectedSchoolType = e.target.value;
	
		// Filter the schools based on the selected school type
		const filteredSchools = this.state.Schools.filter(school => 
			school.school_type === selectedSchoolType && 
			(this.state.establishment_type ? school.establishment_type === this.state.establishment_type : true) &&
			(this.state.proprietor ? school.proprietor === this.state.proprietor : true)
		);
	
		this.setState({
			school_type: selectedSchoolType,        // Update the selected school type
			filteredSchools: filteredSchools,       // Update the filtered list of schools
		});
	}
	
	 
	 //Filter schools automatically
	 autoFilterSchools(){
		 
		  //axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		  axios.get(API_URL + `/schools/filter/${this.state.zone_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
			   this.setState({
				   Schools:response.data,
				   //loading : false,
			   })
		}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the schools. You should retry..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
	 }
	 
  //Edit user 
  triggerGraph(id) {
	
    //this.setState({loading : true});	
	//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	axios.get(API_URL + `/recommendations/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	  //console.log(response.data[0]);
	  this.setState({
		  id : response.data[0].recommendation_id,
		  recommendation_description : response.data[0].recommendation_description,
		  category_id : response.data[0].category_id,
		  visit_id : response.data[0].visit_id,
		  EditModalVisible:true,
		  //loading : false,
	  })
	}).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the data. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
  
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([division, sector])=>{
		 this.setState({
			 Divisions:division.data,
			 Sectors:sector.data,
			 loading : false,
		 })
	 }
	 ).catch(error => {
			  
			  this.setState({loading : false});
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the data. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
	


  handleInput(e) {
    e.preventDefault();

    const { name, value } = e.target;

    // General logic for state updates
    const stateUpdate = {};

    if (value !== '') {
        stateUpdate[`${name}State`] = 'has-success';
        stateUpdate[name] = value;
    } else {
        stateUpdate[`${name}State`] = 'has-danger';
    }

    // Conditional reset of dependent fields
    if (name === 'emis_id') {
        this.setState({ emis_id: '' });
    } else if (name === 'zone_id') {
        this.setState({ emis_id: '' });
    } else if (name === 'district_id') {
        this.setState({ zone_id: '', emis_id: '' });
    } else if (name === 'division_id') {
        this.setState({ district_id: '', zone_id: '', emis_id: '' });
    } else if (name === 'sector_id') {
        this.setState({ division_id: '', district_id: '', zone_id: '', emis_id: '' });
    }

    // Date formatting for start_date and end_date
    if (name === 'start_date' || name === 'end_date') {
        if (value !== '') {
            stateUpdate[name] = this.formatDate(value);
        }
    }

    // Update the state with the final changes
    this.setState(stateUpdate);
}


  render() {
    const {Divisions, Sectors} = this.state;
    

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Performance Report</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
				<Link className="btn btn-primary " to={`/inspection_panel/` + this.state.visit_id } ><ArrowLeft />Back</Link>
			  </div>
			</Col>
          </Row>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Performance Report</h3>
                <hr/>
				{
					/*
						NOTE the usage of the onChange and onBlur event to call the same function.
						The onBlur event has been used to enforce validation once the user move out of the field.
					*/
				}
				
				<div className="container-fluid">
  <div className="row">
    <table className='table'>
      <thead>
        <tr>
          <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Start date</th>
          <th className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">End date</th>
          <th hidden={(this.state.urole=='Division Manager' || this.state.urole=='DEM' || this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">Division</th>
          <th hidden={(this.state.urole=='DEM' || this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false} className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">District</th>
          <th hidden={(this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false} className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{this.state.sector_id==1?'Zone':this.state.sector_id==2?'Cluster':'Zone/Cluster'}</th>
          <th className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">Establishment Type</th>
          <th className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">Proprietor</th>
          <th className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">School Type</th>
		  <th hidden={(this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false} className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">School</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <Input type="date" name="start_date" id="start_date" invalid={this.state.StartDateState==='has-danger'} onChange={(e)=>{this.handleInput(e);}} required={true}/> 
            <FormFeedback invalid>
              The field is required.
            </FormFeedback>
          </td>
          <td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            <Input type="date" name="end_date" id="end_date" invalid={this.state.EndDateState==='has-danger'} onChange={(e)=>{this.handleInput(e);}} required={true} /> 
            <FormFeedback invalid>
              The field is required.
            </FormFeedback>
          </td>
          <td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" hidden={(this.state.urole=='Division Manager' || this.state.urole=='DEM' || this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false}>
            <Input type="select" name="division_id" id="division_id" invalid={this.state.DivisionIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterDistricts(e);}} required={true}> 
              <option></option>
              {
                Divisions.map(division => (
                  <option value={division.division_id} key={division.division_id}>{division.division_name}</option>
                ))
              }
            </Input>
            <FormFeedback invalid>
              The field is required.
            </FormFeedback>
          </td>
          <td className="col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" hidden={(this.state.urole=='DEM' || this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false}> 
            <Input type="select" name="district_id" id="district_id" invalid={this.state.DistrictIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterZones(e);}} required={true}> 
              <option></option>
              {
                this.state.Districts.map(district => (
                  <option value={district.district_id} key={district.district_id}>{district.district_name}</option>
                ))
              }
            </Input>
            <FormFeedback invalid>
              The field is required.
            </FormFeedback>
          </td>
          <td className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" hidden={(this.state.urole=='PEA' || this.state.urole=='Secondary Advisor' || this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false}>
            <Input type="select" name="zone_id" id="zone_id" invalid={this.state.ZoneIdState==='has-danger'} onChange={(e)=>{this.handleInput(e); this.filterSchools(e); }}> 
              <option></option>
              {
                this.state.Zones.map(zone => (
                  this.state.sector_id === zone.sector_id ?
                  <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option> :
                  (this.state.sector_id === 'null') && <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
                ))
              }
            </Input>
            <FormFeedback invalid>
              The field is required.
            </FormFeedback>
          </td>

		  <td>
    <Input type="select" name="establishment_type" value={this.state.establishment_type} onChange={(e) => { this.handleInput(e); this.filterByEstablishmentType(e); }}>
        <option value="">Select Establishment Type</option>
        {
            this.state.establishmentTypes.map(type => (
                <option value={type} key={type}>{type}</option>
            ))
        }
    </Input>
</td>


<td>
    <Input type="select" name="proprietor" value={this.state.proprietor} onChange={(e) => { this.handleInput(e); this.filterByProprietor(e); }}>
        <option value="">Select Proprietor</option>
        {
            this.state.proprietors.map(proprietor => (
                <option value={proprietor} key={proprietor}>{proprietor}</option>
            ))
        }
    </Input>
</td>


<td>
    <Input type="select" name="school_type" value={this.state.school_type} onChange={(e) => { this.handleInput(e); this.filterBySchoolType(e); }}>
        <option value="">Select School Type</option>
        {
            this.state.schoolTypes.map(type => (
                <option value={type} key={type}>{type}</option>
            ))
        }
    </Input>
</td>


<td className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" hidden={(this.state.urole=='Head Teacher' || this.state.urole=='Teacher')?true:false}>
    <Input type="select" name="emis_id" id="emis_id" invalid={this.state.EmisIdState==='has-danger'} onChange={this.handleInput} required={true}> 
        <option value="">Select School</option>
        {
            this.state.filteredSchools.length > 0 ? (
                this.state.filteredSchools.map(school => (
                    <option value={school.emis_id} key={school.emis_id}>{school.school_name}</option>
                ))
            ) : (
                <option value="">No schools available</option>
            )
        }
    </Input>
    <FormFeedback invalid>The field is required.</FormFeedback>
</td>


        </tr>
      </tbody>
    </table>
    <div className="text-danger" hidden={(this.state.urole=="Head Teacher" || this.state.urole=="Teacher" || this.state.urole=="PTA")?true:false}>
      ** 
      <i>
      The form above is for the customization for the charts below and is crucial in filtering(Top-Botton) the graphs to display below. You're then advised
      to filter the data based on the table header fields and the graph will display accordingly. It should 
      also be noted that the graph will automatically appear after filtering, and initially the default charts are based on your Juridistiction.
      </i>
      **
    </div>
  </div>
</div>

                <hr/>

                {
                      //Add user moadl with filteres for report data
                }
                
				
				<div className="container-fluid">
					<div className="row">
						{
							(this.state.start_date != '' || this.state.end_date!='') &&
								(
									<NationalAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} />
								)
						}
						
						{
							(this.state.start_date != '' || this.state.end_date!='') &&
								(
									<NationalNesAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} />
								)
						}

						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.division_id!='') &&
									(<DivisionAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} division_id={this.state.division_id}/>)
									
						}
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.division_id!='') &&
									(<DivisionNesAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} division_id={this.state.division_id}/>)
									
						}
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.district_id!='') &&
									(<DistrictAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} district_id={this.state.district_id}/>)
									
						}
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.district_id!='') &&
									(<DistrictAvgNesPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} district_id={this.state.district_id}/>)
									
						}
						
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.zone_id!='') &&
									(<ClusterAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} zone_id={this.state.zone_id} />)
									
						}
						
						
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.zone_id!='') &&
									(<ClusterNesAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} zone_id={this.state.zone_id}/>)
									
									
						}
						{/* Establishment Type Performance Graph */}
    {this.state.establishment_type && (
     <EstablishmentTypePerformanceGraph
	 filteredData={this.state.filteredSchools} // Pass filtered schools data
	 start_date={this.state.start_date}
	 end_date={this.state.end_date}
   />
   
    )}

    {/* Proprietor Performance Graph */}
    {this.state.proprietor && (
      <ProprietorPerformanceGraph
        filteredData={this.state.filteredSchools} // Pass filtered schools data
        start_date={this.state.start_date}
        end_date={this.state.end_date}
        proprietor={this.state.proprietor}
      />
    )}

    {/* School Type Performance Graph */}
    {this.state.school_type && (
      <SchoolTypePerformanceGraph
        filteredData={this.state.filteredSchools} // Pass filtered schools data
        start_date={this.state.start_date}
        end_date={this.state.end_date}
        school_type={this.state.school_type}
      />
    )}
						{
							((this.state.start_date != '' || this.state.end_date!='') && this.state.emis_id!='') &&
									(<SchoolAvgPerformanceGraph start_date={this.state.start_date} end_date={this.state.end_date} emis_id={this.state.emis_id}/>)
									
						}

											
						
					</div>
				</div>
				  
				  
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default PerformanceReport;
