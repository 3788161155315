import React, { Component, Suspense } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
// Custom Scrollbar
import { Link } from "react-router-dom";

import axiosPost from '../../../services/axios';
import axios from 'axios';
import Loader from "../../../components/Loader";

import SchoolsAdmissions from "../Charts/SystemAdmin/SchoolsAdmissions";
import InspectedSchools from "../Charts/SystemAdmin/InspectedSchools";
import InspectedSecondarySchools from "../Charts/SystemAdmin/InspectedSecondarySchools";
import InspectedPrimarySchools from "../Charts/SystemAdmin/InspectedPrimarySchools";
import TotalSchools from "../Charts/SystemAdmin/TotalSchools";
//import NationalPerformanceReport from "../Reports/NationalAvgPerformanceGraph";
//import NationalNesPerformanceReport from "../Reports/NationalNesAvgPerformanceGraph";
import ProgressGraph from "../Charts/SystemAdmin/ProgressGraph";
import moment from "moment";


import "chartist/dist/scss/chartist.scss";
import { Add, Edit, Delete, ArrowLeft, Poll, Grade, School, Apartment } from '@material-ui/icons';
import { Container, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';


import backend_url from "../../../routes/API_URL";

import swal from 'sweetalert';


const API_URL = backend_url['api_url'];
//const API_URL = "http://localhost:8000/api";

class SystemAdminDashboard extends Component {
	constructor(props) {
		super(props);
		//const visit_id=this.props.match.params.visit_id;

		this.state = {
			Sec_Data: [],
			Prim_Data: [],
			Schools: [],
			Zones: [],
			Clusters: [],
			Constituencies: [],
			Districts: [],
			Divisions: [],
			Standards: [],
			Requirements: [],
			Levels: [],
			NesCategories: [],
			bearer_auth: window.sessionStorage.getItem('token'),
			loading: '',
			startDate: moment().startOf('year').format('YYYY-MM-DD'),
			endDate: moment().endOf('year').format('YYYY-MM-DD')
		};

		this.abortController = new AbortController();
		this.sendNESNotifications = this.sendNESNotifications.bind(this);

	}

	sendNESNotifications() {
		this.setState({
			loading: true
		})
		axiosPost.post(`${API_URL}/nes_requirements/send_notifications?start_date=${this.state.startDate}&end_date=${this.state.endDate}`)
			.then(resp => {
				if (resp.status < 300) {
					this.setState({
						loading: false
					})
					swal({
						title: 'Success',
						text: 'Successfully sent emails for specified visits',
						icon: 'success'
					})
				} else {
					swal({
						title: 'Error',
						text: 'Something went wrong',
						icon: 'error'
					})
				}
			})
	}


	componentDidMount() {
		this.setState({ loading: true });

		Promise.all([
			axios.get(API_URL + `/total_schools/primary`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_schools/secondary`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_zones`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_clusters`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_constituencies`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_districts`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_nes`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_nes_requirements`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_nes_levels`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),
			axios.get(API_URL + `/total_nes_categories`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` }, signal: this.abortController.signal }),

		]).then(
			([p_data, s_data, zone, cluster, constituency, district, division, standard, req, level, category]) => {
				this.setState({
					Prim_Data: p_data.data[0],
					Sec_Data: s_data.data[0],
					Zones: zone.data[0],
					Clusters: cluster.data[0],
					Constituencies: constituency.data[0],
					Districts: district.data[0],
					Divisions: division.data[0],
					Standards: standard.data[0],
					Requirements: req.data[0],
					Levels: level.data[0],
					NesCategories: category.data[0],
					loading: false,
				})
			}
		).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "error",
					timer: 2000
				})
					.then((sucess) => {
						// window.sessionStorage.clear();
						// window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: "Failed to load the dashboard data. We will refresh the page in seconds..",
					icon: "error",
					timer: 5000
				})
					.then((sucess) => {
						// window.location.reload();
					});
			}
		});


	}

	componentWillUnmount() {
		this.abortController.abort();
	}

	render() {
		const { Prim_Data, Sec_Data, Zones, Clusters, Constituencies, Districts, Divisions, Standards, Requirements, Levels, NesCategories } = this.state;

		return (
			<React.Fragment>
				<div className="container-fluid">
					<Row className="position-center">
						{(this.state.loading == true) ? <Loader /> : null}
					</Row>
					<Row className="align-items-center">
						<Col sm={6}>
							<div className="page-title-box">
								<h4 className="font-size-18">SYSTEM ADMINISTRATION LEVEL</h4>
								<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item">
										<Link to="/#">Dashboard</Link>
									</li>
									<li className="breadcrumb-item active">Visualization</li>
								</ol>
							</div>
						</Col>

						<Col sm="6">
							<div className="float-right d-none d-md-block">

							</div>
						</Col>
					</Row>

					<Row>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card style={{ padding: '1em' }}>
								<hr />
								<h6>Trigger Notifications for Recommendations to Directorates</h6>
								<hr />
								<Form>
									<FormGroup>
										<Row>
											<Col xs={5} sm={5} md={5} lg={5} xl={5}>
												Start Date
											</Col>
											<Col xs={5} sm={5} md={5} lg={5} xl={5}>
												End Date
											</Col>
											<Col xs={2} sm={2} md={2} lg={2} xl={2}>

											</Col>


										</Row>
									</FormGroup>
									<FormGroup>
										<Row>
											<Col xs={5} sm={5} md={5} lg={5} xl={5}>
												<Input type="date" onChange={(e) => {
													const { value } = e.target
													this.setState({ startDate: moment(value).format("YYYY-MM-DD") })
												}} defaultValue={this.state.startDate} name="activity_start_date" id="activity_start_date" required={true} />
												<FormFeedback invalid>
													The field is required.
												</FormFeedback>
											</Col>
											<Col xs={5} sm={5} md={5} lg={5} xl={5}>
												<Input type="date" defaultValue={this.state.endDate} onChange={(e) => {
													const { value } = e.target
													this.setState({ endDate: moment(value).format("YYYY-MM-DD")})
												}} name="activity_finish_date" id="activity_finish_date" required={true} />

											</Col>
											<Col xs={2} sm={2} md={2} lg={2} xl={2}>
												<Button onClick={this.sendNESNotifications} color="success">Trigger</Button>
											</Col>


										</Row>
									</FormGroup>

								</Form>
							</Card>

						</Col>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card>
								<CardBody>
									<h6 className="card-title mb-4">QUICK STATISTICS</h6>
									<hr className="bg-success" />
									<Row className="text-white">
										<Col xs={12} sm={12} md={3} lg={3} xl={3}>
											<Link className="text-success" to="#">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Primary Schools</span>
														<span className="float-right"><School fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Prim_Data['COUNT']}</h2></strong></span></center>
													</CardBody>
												</Card>
											</Link>

										</Col>

										<Col xs={12} sm={12} md={3} lg={3} xl={3}>
											<Link className="text-success" to="#">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Secondary Schools</span>
														<span className="float-right"><School fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Sec_Data['COUNT'] - 12}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>

										</Col>

										<Col xs={12} sm={12} md={3} lg={3} xl={3}>
											<Link className="text-success" to="#">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Zones</span>
														<span className="float-right"><Apartment fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Zones['COUNT']}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>

										</Col>
										<Col xs={12} sm={12} md={3} lg={3} xl={3}>
											<Link className="text-success" to="#">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Clusters</span>
														<span className="float-right"><Apartment fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Clusters['COUNT']}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>

										</Col>



									</Row>
									<hr className="bg-success" />
									<Row hidden className="text-white">
										<Col xs={12} sm={12} md={4} lg={4} xl={4}>
											<Link className="text-success" to="/constituencies">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left"> Constituencies</span>
														<span className="float-right"><Apartment fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Constituencies['COUNT']}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>
										</Col>

										<Col xs={12} sm={12} md={4} lg={4} xl={4}>
											<Link className="text-success" to="/districts">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Districts</span>
														<span className="float-right"><Grade fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Districts['COUNT']}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>
										</Col>

										<Col xs={12} sm={12} md={4} lg={4} xl={4}>
											<Link className="text-success" to="/divisions">
												<Card className="border border-success">
													<CardBody>
														<span className="float-left">Divisions</span>
														<span className="float-right"><Grade fontSize="large" /></span>
														<br />
														<hr />
														<center><span fontSize="large"><strong><h2>{Divisions['COUNT']}</h2></strong></span></center>

													</CardBody>
												</Card>
											</Link>
										</Col>


									</Row>



								</CardBody>
							</Card>
						</Col>
					</Row>


					<Row>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card>
								<CardBody>


									<InspectedSchools />

								</CardBody>
							</Card>
						</Col>


					</Row>

					<Row>
						{
							/* <NationalPerformanceReport />
							
							<NationalNesPerformanceReport /> */
						}

					</Row>

					<Row>
						<Col xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card>
								<CardBody>


									<ProgressGraph />

								</CardBody>
							</Card>
						</Col>


					</Row>




				</div>
			</React.Fragment>
		);
	}
}

export default SystemAdminDashboard;
