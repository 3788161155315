import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import backend_url from "../../../routes/API_URL";
import moment from "moment";
import swal from 'sweetalert';

const API_URL = backend_url['api_url'];
class NationalProgressGraph extends Component {
	
   constructor(props) {
	
    super(props);
	//const visit_id=this.props.visit_id;
	
	   const end_date = new Date().getFullYear();
	   const start_date = end_date - 3;
	
    this.state = {
	  GraphicalData: [],
	  chartType: 'line',
	  header: props.header ? props.header : 'years',
	  bearer_auth:window.sessionStorage.getItem('token'),
	  start_date: props.start ? new Date(props.start).getFullYear() : start_date,
	  end_date: props.end ? new Date(props.end).getFullYear() : end_date,
	  start: moment(props.start).startOf('year').format("YYYY-MM-DD"),
		end: moment(props.end).endOf('year').format("YYYY-MM-DD"),
		level: props.level ? props.level : null
	  //Update controllers
    };
    
  }
  
  componentDidMount () {
	 
	 Promise.all([axios.get(API_URL + `/progress_report?start_date=${this.state.start}&end_date=${this.state.end}&level=${this.state.level}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([g_data])=>{
		 this.setState({
			 GraphicalData:g_data.data,
		 });
		 
		 //console.log(g_data);
	 }
	 ).catch(error => {
			  if(error.response.status=='401'){
						swal({
						  title: "",
						  text: "Your session has expired. You'll automatically be logged out within seconds.",
						  icon: "warning",
						  timer : 2000
						})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				  }else{
					  swal({
						  title: "",
						  text: "Failed to load the graphical data for the school. You should refresh the page..",
						  icon: "error",
						  button:true
						})
						.then((sucess) => {
							//window.location.reload();
						});
				  }
          })
  }
  toggleChartType = () => {
    this.setState((prevState) => ({
      chartType: prevState.chartType === 'line' ? 'bar' : 'line'
    }));
  };
  
  
  render() {
    const {GraphicalData, chartType} = this.state;
	//const label = [];
	//const serie = [];
	//console.log(graphical_data);
	
	//Find the frequency of elements in an array/object
	const entryFrequency = (obj) =>{
		const level_freq = {};
		
		obj.forEach(idx=>{
			if(level_freq[idx]){
				level_freq[idx]++;
			}else{
				level_freq[idx] = 1;
			}
		});
		
		return level_freq
	}
	
	const years = [];
	const level_data = [];
	
	
	//Capture the unique division names. You may altrenatively use the set() object 
	for(let i=0;i<GraphicalData.length;i++){
		let formated_date= GraphicalData[i]['DATE'];
		
		if(i==0 && this.state.end_date>=this.state.start_date && (formated_date>=this.state.start_date && formated_date<=this.state.end_date)){
			years.push(formated_date);
			//console.log(formated_date);
		}else if(i>=1 && years.includes(formated_date)==false && this.state.end_date>=this.state.start_date && (formated_date>=this.state.start_date && formated_date<=this.state.end_date)){
			years.push(formated_date);
			//console.log(formated_date);
		}else{
			continue;
		}
	}
	
	let level_one_data = [];
	let level_two_data = [];
	let level_three_data =[];
	let level_four_data = [];
	
	//Default all level_*_data based on the length of the divisions
	
	for(let idx=0;idx<years.length;idx++){
		level_one_data.push(0);
		level_two_data.push(0);
		level_three_data.push(0);
		level_four_data.push(0);
	}
	
	/**
	Come up a dynamic series' data
	**/
	
	
	//Loop through the divisions
	for(let m=0;m<years.length;m++){
		
		const uncategorised_levels = [];
		//Loop through the data from the database 
		for(let n=0;n<GraphicalData.length; n++){
			
			let formated_date= GraphicalData[n]['DATE'];
			//First condition
			
			
			
			if(years[m]==formated_date && this.state.end_date>=this.state.start_date && (formated_date>=this.state.start_date && formated_date<=this.state.end_date)){
				uncategorised_levels.push(GraphicalData[n]['level_achieved']);
			}
		}
		
		const categorised_levels = entryFrequency(uncategorised_levels);
		let total_level_data = 0;
		
		for(const v in categorised_levels){
			total_level_data+=categorised_levels[v];
		}
	    
		
		//console.log(categorised_levels);
		//console.log(total_level_data);
		
		//const cat_length = categorised_levels.length;
		
		//length 
		let cat_length=0;
		
		for(const idx in categorised_levels){
			cat_length+=1;
		}
		
		//console.log(cat_length);
		//Map data to levels while balancing up the lengths
		for(const idx in categorised_levels){
			
			if(idx=='1'){
				level_one_data[m]=parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='2'){
				level_two_data[m]= parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='3'){
				level_three_data[m]=parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}else if(idx=='4'){
				level_four_data[m] =parseFloat((categorised_levels[idx]/total_level_data)*100).toFixed(2);
			}
			
			
			
		}
		
		
	}

			
	const series = [{
	  name: 'Below Minimum',
	  data: level_one_data
	}, {
	  name: 'Met Minimum',
	  data: level_two_data
	}, {
	  name: 'Exceeded Minimum',
	  data: level_three_data
	}, {
	  name: 'Effective Level',
	  data: level_four_data
	},];
	
	
	const options = {
		chart: {
		  type: chartType,
		  height: 500,
		},
		dataLabels: {
		  enabled: false,
		  formatter: (value) => value.toFixed(2) + '%',
		},
		colors: ['#ff0000', '#0018ff', '#fffc00', '#1bb60b'],
		stroke: {
			width: chartType === 'line' ? 2 : 0,
		  },
		title: {
		  text: this.state.header && this.state.header === 'dates' 
			? `National Progress Report from ${this.state.start} to ${this.state.end}` 
			: `National Progress Report from ${this.state.start_date} to ${this.state.end_date} (${this.state.end_date - this.state.start_date + 1} Year(s))`,
		  align: 'center',
		  margin: 10,
		  style: {
			fontSize: '12px',
			fontWeight: 'bold',
			color: '#263238',
		  },
		},
		xaxis: {
		  categories: years,
		  title: {
			text: 'INSPECTION YEAR(S)',
		  },
		},
		yaxis: {
		  title: {
			text: 'PERCENTAGE OF SCHOOLS',
		  },
		  max: 100,
		  labels: {
			formatter: (value) => value.toFixed(0) + '%',
		  },
		},
		tooltip: {
		  y: {
			formatter: (value) => value.toFixed(2) + '%',
		  },
		},
		legend: {
		  position: 'bottom',
		},
	  };
	
    return (
      <React.Fragment>
        <div className="colxs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<div className="card">
			<FormControlLabel
            control={
              <Switch
                checked={chartType === 'bar'}
                onChange={this.toggleChartType}
                color="primary"
              />
            }
            label={chartType === 'line' ? 'Switch to Bar Chart' : 'Switch to Line Chart'}
          />
			<ReactApexChart id="chart" options={options} series={series} type = {chartType} height="500" />

			</div>
		</div>
		
		
      </React.Fragment>
    );
  }
}

export default NationalProgressGraph;
