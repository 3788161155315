import React, { Component } from "react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Container, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter,Table,Button, Form, FormGroup, Label, Input, FormText} from 'reactstrap';
import Avatar from '@mui/material/Avatar';

import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import {Add, Edit, Delete} from '@material-ui/icons';
import swal from 'sweetalert';

import Loader from "../../../components/Loader";


import backend_url from "../../../routes/API_URL";

const API_URL = backend_url['api_url'];

//const API_URL = "http://localhost:8000/api";
//import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';

class ErrorLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
	  ErrorLogs:[],
      bearer_auth:window.sessionStorage.getItem('token'),
	  loading:'',
    };
  }
  
  componentDidMount () {
	 
	 this.setState({loading : true});
	 
	 //Promise.all([axios.get(`/api/exams/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(`/api/subjects/assigned/teacher/${teacher_id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 //Promise.all([axios.get(API_URL + `/api/users`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/divisions`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}),axios.get(API_URL + `/api/sectors`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 Promise.all([axios.get(API_URL + `/logs`,{headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})]).then(
	 ([log])=>{
		 this.setState({
			 ErrorLogs:log.data,
			 loading : false,
		 })
	 }
	 ).catch(error => {
			  
			  //this.abortController.abort();
			  this.setState({loading : false});
			  
			  if(error.response.status=='401' ){
					swal({
					  title: "",
					  text: "Your session has expired. You'll automatically be logged out within seconds.",
					  icon: "warning",
					  timer : 2000
					})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			  }else{
				  swal({
					  title: "",
					  text: "Failed to load the error logs. We will refresh the page in seconds..",
					  icon: "error",
					  button:true
					})
					.then((sucess) => {
						//window.location.reload();
					});
			  }
          });
  }
  

  render() {
    const {ErrorLogs} = this.state;
	
    const data = {
      columns: [
        { 
          title: 'Level',
           field: 'level' 
        },
        {
          title: 'Context',
          field: 'context',
        },
		{
          title: 'Content',
          field: 'content',
        },
        {
          title: 'Created On',
          field: 'created_on',
        },
        
      ],
      
    };

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="position-center">
			{(this.state.loading == true) ? <Loader /> : null}
		  </Row>
		  <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  
                  <li className="breadcrumb-item active">Error logs</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-none d-md-block">
                
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                <h3 className="card-title">Error Logging</h3>
                <hr/>
                
				<table className="table table-responsive table-bordered table-condensed table-hover table-striped">
                      <MaterialTable
                          title="Error Logs"
                          columns={data.columns}
                          data={
								ErrorLogs.map(log=>(	
									{
									  level: log.level,
									  context: log.context,
									  created_on: new Date(log.date).toUTCString(),
									  content: log.text,
									}
						  ))} 
						  
                          options={{
                            exportButton: true,
							tableLayout: 'auto',
							loadingType : 'overlay',
							pageSizeOptions : [5,20, 50, 100, 500, 1000],
							emptyRowsWhenPaging: false,
							columnsButton : true,
							exportAllData : true,
							actionsColumnIndex: -1,
							search: true,
							sorting: true
                          }}
                        />

                  </table>

                </div>
              </div>
            </div>
          </div>

         
        </div>
      </React.Fragment>
    );
  }
}

export default ErrorLogs;
