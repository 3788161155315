import React, { Component } from "react";
import ReactApexChart from 'react-apexcharts';

class EstablishmentTypePerformanceGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      GraphicalData: this.props.filteredData, // Using filtered data passed as a prop
      start_date: this.props.start_date,
      end_date: this.props.end_date,
      establishmentTypes: ['Form 1-4', 'Form 1-2', 'Form 2-3', 'Form 3-4', 'Full Primary', 'LEA'], // List of all types
      size: this.props.size,
    };
  }

  componentDidUpdate(prevProps) {
    // Check if new filtered data or dates are passed as props
    if (
      this.props.filteredData !== prevProps.filteredData ||
      this.props.start_date !== prevProps.start_date ||
      this.props.end_date !== prevProps.end_date
    ) {
      this.setState({
        GraphicalData: this.props.filteredData,
        start_date: this.props.start_date,
        end_date: this.props.end_date,
      }, () => {
        console.log('Updated GraphicalData:', this.state.GraphicalData); // Debugging log
      });
    }
  }

  render() {
    const { GraphicalData, establishmentTypes } = this.state;

    console.log('Rendered GraphicalData:', GraphicalData); // Debugging log

    // Function to calculate the frequency of performance levels
    const entryFrequency = (obj) => {
      const level_freq = {};
      obj.forEach(idx => {
        if (level_freq[idx]) {
          level_freq[idx]++;
        } else {
          level_freq[idx] = 1;
        }
      });
      return level_freq;
    };

    let level_one_data = [];
    let level_two_data = [];
    let level_three_data = [];
    let level_four_data = [];

    // Initialize level data arrays with zeros for each establishment type
    establishmentTypes.forEach(() => {
      level_one_data.push(0);
      level_two_data.push(0);
      level_three_data.push(0);
      level_four_data.push(0);
    });

    // Process the filtered data
    establishmentTypes.forEach((type, index) => {
      const filteredSchools = GraphicalData.filter(school => school.establishment_type === type);

      console.log(`Filtered schools for ${type}:`, filteredSchools); // Debugging log

      const uncategorized_levels = filteredSchools.map(school => school.performance_level);
      const categorized_levels = entryFrequency(uncategorized_levels);
      let total_level_data = 0;

      // Calculate the total number of schools for each level
      for (const level in categorized_levels) {
        total_level_data += categorized_levels[level];
      }

      console.log(`Categorized levels for ${type}:`, categorized_levels); // Debugging log

      // Assign data to the respective level arrays
      level_one_data[index] = categorized_levels['1'] ? (categorized_levels['1'] / total_level_data) * 100 : 0;
      level_two_data[index] = categorized_levels['2'] ? (categorized_levels['2'] / total_level_data) * 100 : 0;
      level_three_data[index] = categorized_levels['3'] ? (categorized_levels['3'] / total_level_data) * 100 : 0;
      level_four_data[index] = categorized_levels['4'] ? (categorized_levels['4'] / total_level_data) * 100 : 0;
    });

    const series = [
      { name: 'Level 1', data: level_one_data },
      { name: 'Level 2', data: level_two_data },
      { name: 'Level 3', data: level_three_data },
      { name: 'Level 4', data: level_four_data },
    ];

    console.log('Series Data:', series); // Debugging log

    const options = {
      chart: { type: 'bar', height: 300, stacked: true, stackType: '100%' },
      colors: ['#ff0000', '#0018ff', '#fffc00', '#1bb60b'],
      xaxis: { categories: establishmentTypes },
      yaxis: {
        max: 100,
        labels: { formatter: (value) => value.toFixed(0) + '%' },
      },
      title: {
        text: `Performance by Establishment Type from ${this.state.start_date} to ${this.state.end_date}`,
        align: 'center',
        style: { fontSize: '12px', fontWeight: 'bold', color: '#263238' },
      },
      legend: { position: 'bottom' },
    };

    return (
      <div className={this.state.size === "fullscreen" ? "col-xs-12" : "col-xs-12 col-md-6"}>
        <div className="card">
          <ReactApexChart options={options} series={series} type="bar" height={300} />
        </div>
      </div>
    );
  }
}

export default EstablishmentTypePerformanceGraph;
